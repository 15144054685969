<template>
    <div style="height: calc(100vh - 300px); width: 100%">
        <!-- 内容区 -->
        <div style="margin: 20px;">
            <!--    法条-->
            <subjective-law />
        </div>
    </div>

</template>

<script>
import SubjectiveLaw from "../components/subjectiveLaw";

export default {
    name: "lawTree",
    components: {SubjectiveLaw}
}
</script>